@import "foundation-sites/scss/foundation";

@include foundation-print-styles;

@media print {
  .print-row,
  .print-column {
    box-sizing: border-box;
  }
  .print-row {
    display: flex;
    flex-wrap: wrap;
    .print-column {
      flex: 1 1 50%;
      padding: 15px;
    }
  }
}
